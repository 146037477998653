<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBContainer class="mt-3" bg="white">
    <MDBRow>
      <MDBCol>
        <MDBCard>
          <MDBCardBody>
            <form @submit="Submit">
              <MDBCardText>
                <div class="row mb-3">
                  <div class="col-md-4">
                    更新 SiteMap <a class="active exact-active ripple-surface" href="javascript:void(0)" @click="updateSiteMap()">點我更新</a>
                  </div>
                  <div class="col-md-8">
                    網址: <code>{{ url+'/sitemap.xml' }}</code>
                  </div>
                </div>
                <MDBRow>
                  <MDBCol md="4">
                    <label>{{ $t("SEO.HEADER") }}</label>
                  </MDBCol>
                  <MDBCol md="8">
                    <textarea
                      rows="10"
                      cols=""
                      class="form-control"
                      v-model="Form.header"
                    ></textarea>
                    <span class="text-danger">{{ headerError }}</span>
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                  <MDBCol md="4">
                    <label>{{ $t("SEO.BODY") }}</label>
                  </MDBCol>
                  <MDBCol md="8">
                    <textarea
                      rows="10"
                      cols=""
                      class="form-control"
                      v-model="Form.body"
                    ></textarea>
                    <span class="text-danger">{{ bodyError }}</span>
                  </MDBCol>
                </MDBRow>
              </MDBCardText>
              <MDBRow>
                <MDBCol>
                  <MDBBtn color="primary" type="submit" :disabled="SubmitLoading">
                    {{ $t("COMMON.SAVE") }}
                    <span
                      class="spinner-border Loading"
                      :class="{ 'd-none': !SubmitLoading }"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </span>
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBCardText,
} from "mdb-vue-ui-kit";
import Breadcrumbs from "@/components/Breadcrumbs";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { useForm, useField } from "vee-validate";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import { ref, reactive } from "vue";
import CommonService from "@/core/services/common.service";

export default {
  name: "SeoManage",
  components: {
    MDBContainer,
    Breadcrumbs,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBBtn,
  },
  setup() {
    const SubmitLoading = ref(false);
    const uuid = "9a816f01-1e38-427e-80b3-ff4469bde91d";
    const baseUrl = `Admin/v1/sysconfig/seo`;
    const url = process.env.VUE_APP_API_URL;
    const i18n = useI18n();

    const basicSchema = {
      header: yup.string().required().label(i18n.t("SEO.HEADER")),
      body: yup.string().required().label(i18n.t("SEO.BODY")),
    };

    const FormSchema = yup.object(Object.assign(basicSchema));

    const { handleSubmit } = useForm({
      validationSchema: FormSchema,
    });

    const { value: header, errorMessage: headerError } = useField("header");
    const { value: body, errorMessage: bodyError } = useField("body");

    const Form = reactive({ header, body });

    // 取得SEO資訊
    const getSettings = async () => {
      return await ApiService.get(baseUrl, uuid).then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data;

          Form.header = responseData.header;
          Form.body = responseData.body;
        }
      });
    };

    // 資料送出
    const Submit = handleSubmit((values) => {
      SubmitLoading.value = true;
      ApiService.update(baseUrl, uuid, values).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "",
            text: `${i18n.t("COMMON.EDIT")}${i18n.t("COMMON.SUCCESS")}`,
            icon: "success",
            confirmButtonClass: i18n.t("COMMON.CONFIRM"),
          });

          SubmitLoading.value = false;
        }
      });
      return false;
    });

    const updateSiteMap = function() {
      ApiService.get("/Admin/sitemap", '',{}).then(
        response => {
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
          }
        }
      );
    };

    return {
      getSettings,
      SubmitLoading,
      Form,
      Submit,
      headerError,
      bodyError,
      updateSiteMap,
      url
    };
  },
  async mounted() {
    Promise.all([await this.getSettings()]).then(() => {
      this.PageLoading = false;
    });
  },
};
</script>
